import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  permissions: {
    mobilizer_management: {
      all: false,
      write: false,
      delete: false,
      read: false,
    },
    center_management: { all: false, write: false, delete: false, read: false },
    job_management: { all: false, write: false, delete: false, read: false },
    referral_management: {
      all: false,
      write: false,
      delete: false,
      read: false,
    },
    incentive_payouts: { all: false, write: false, delete: false, read: false },
    query_management: { all: false, write: false, delete: false, read: false },
    faq: { all: false, write: false, delete: false, read: false },
    categories: { all: false, write: false, delete: false, read: false },
    role_permission: { all: false, write: false, delete: false, read: false },
    dashboard: { all: false, write: false, delete: false, read: false },
    settings: { all: false, write: false, delete: false, read: false },
  },
  role_access: {},
  isPermissionSet: false,
};

const roleBasedActionSlice = createSlice({
  name: "roleBasedAction",
  initialState,
  reducers: {
    setPermissions: (state, action) => {
      const data = action.payload;
      data.WRITE?.map((e) => {
        state.permissions[`${e}`].write = true;
        state.permissions[`${e}`].read = true;
      });
      data.DELETE?.map((e) => {
        state.permissions[`${e}`].delete = true;
        state.permissions[`${e}`].read = true;
      });
      data.READ?.map((e) => {
        state.permissions[`${e}`].read = true;
      });
      data.ALL?.map((e) => {
        state.permissions[`${e}`].all = true;
        state.permissions[`${e}`].write = true;
        state.permissions[`${e}`].delete = true;
        state.permissions[`${e}`].read = true;
      });
      state.isPermissionSet = true;
    },
    setRoleAccess: (state, action) => {
      state.role_access = action.payload;
    },
    resetState: () => initialState,
  },
});

export const { setPermissions, setRoleAccess, resetState } =
  roleBasedActionSlice.actions;

export default roleBasedActionSlice.reducer;
