import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../axios";

const initialState = {
  category: [],
  locations: [],
  locationsDropdown: [],
  citiesCount: 0,
  companies: [],
  feilds: [],
  stages: [],
  faqCategories: [],
  mobilizers: [],
};

export const getCompanyOptions = createAsyncThunk(
  "getCompanyForOptions",
  async (args, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/admin/dropdown?type=company`);
      if (response?.status === 200) {
        const data = response?.data?.data?.map((e) => {
          return { id: e?._id, value: e?.name };
        });
        return data;
      }
    } catch (error) {}
  }
);
export const getJobsCategory = createAsyncThunk(
  "getJobsCategoryForOptions",
  async (args, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `/admin/dropdown?type=jobCategory`
      );
      if (response?.status === 200) {
        const data = response?.data?.data?.map((e) => {
          return { id: e?._id, value: e?.name };
        });
        return data;
      }
    } catch (error) {}
  }
);
export const getJobsStages = createAsyncThunk(
  "getJobsStagesForOptions",
  async (args, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/job/get-stages`);
      if (response?.status === 200) {
        const data = response?.data?.data?.map((e) => {
          return { id: e?.id, value: e?.value };
        });
        return data;
      }
    } catch (error) {}
  }
);

export const getLocationOptions = createAsyncThunk(
  "getLocationOptions",
  async (args, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `/admin/dropdown?type=location&limit=${args.limit}&page=${args.pageNumber}&search=${args.searchedKeyword}`
      );
      if (response?.status === 200) {
        const data = response?.data?.data?.cities.map((e) => {
          return {
            id: e?._id,
            value: `${e?.name}, ${e?.state_name}`,
          };
        });
        return { count: response?.data?.data?.count, data };
      }
    } catch (error) {}
  }
);

export const getLocationByIds = createAsyncThunk(
  "getLocationByIds",
  async (args, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/location/locations`, {
        city_ids: args,
      });
      if (response?.status === 200) {
        const data = response?.data?.data?.map((e) => {
          return {
            id: e?._id,
            value: `${e?.name}, ${e?.state_name}`,
          };
        });
        return data;
      }
    } catch (error) {}
  }
);

export const getFeildsOptions = createAsyncThunk(
  "getFeildsForOptions",
  async (args, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/job-category/get-fields`);
      if (response?.status === 200) {
        return response?.data?.data;
      }
    } catch (error) {}
  }
);

export const getFaqCategories = createAsyncThunk(
  "getFaqCategories",
  async (args, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/faq/get-faq-categories`);
      if (response?.status === 200) {
        const data = response?.data?.data?.map((e) => {
          return {
            id: e?.key,
            value: e?.value?.category_en ?? "",
          };
        });
        return data;
      }
    } catch (error) {}
  }
);

export const getMObilizersOptions = createAsyncThunk(
  "getMobilizerOptions",
  async (args, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get("/mobilizer/admin/dropdown");
      if (response?.data?.success) {
        const data =
          response?.data?.data?.map((e) => ({
            id: e?._id ?? null,
            value: `${e?.first_name ?? ""} ${e?.last_name ?? ""}`.trim(),
          })) ?? [];

        return data;
      }
    } catch (error) {}
  }
);

const optionSlice = createSlice({
  name: "optionSlice",
  initialState,
  reducers: {
    resetCitiesCount: (state) => {
      state.citiesCount = 0;
    },
    resetLocationDropdown: (state) => {
      state.locationsDropdown = [];
    },
    resetLocation: (state) => {
      state.locations = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getJobsCategory.fulfilled, (state, action) => {
        state.category = action.payload;
      })
      .addCase(getLocationOptions.fulfilled, (state, action) => {
        state.locations = [...state.locations, ...action.payload.data];
        state.locationsDropdown = [
          ...state.locationsDropdown,
          ...action.payload.data,
        ];
        state.citiesCount = action.payload.count;
      })
      .addCase(getCompanyOptions.fulfilled, (state, action) => {
        state.companies = action.payload;
      })
      .addCase(getJobsStages.fulfilled, (state, action) => {
        state.stages = action.payload;
      })
      .addCase(getFeildsOptions.fulfilled, (state, action) => {
        state.feilds = action.payload;
      })
      .addCase(getMObilizersOptions.fulfilled, (state, action) => {
        state.mobilizers = action.payload;
      })
      .addCase(getFaqCategories.fulfilled, (state, action) => {
        state.faqCategories = action.payload;
      })
      .addCase(getLocationByIds.fulfilled, (state, action) => {
        state.locationsDropdown = [
          ...state?.locationsDropdown,
          ...action?.payload,
        ];
      });
  },
});
export const { resetCitiesCount, resetLocation, resetLocationDropdown } =
  optionSlice.actions;

export default optionSlice.reducer;
