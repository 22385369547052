import jobVarSlice from "./Slices/JobManagement/jobVarSlice";
import optionsSlice from "./Slices/OptionsForSelect/optionsSlice";
import roleBasedActionSlice from "./Slices/RBAC/roleBasedActionSlice";
import referralVarSlice from "./Slices/ReferralManagement/referralVarSlice";
import SidbarVarSlice from "./Slices/SIdebar/SidbarVarSlice";
import refferedcandidateSlice from "./Slices/refferedCandidate/refferedcandidateSlice";
import incentivePayoutsSlice from "./Slices/IncentivePayouts/incentivePayoutsSlice";
import queryManagementSlice from "./Slices/QueryManagement/queryManagementSlice";
import companyCategoriesSlice from "./Slices/CompanyCategories/companyCategoriesSlice";
import referredCandidateFilerSlice from "./Slices/refferedCandidate/referredCandidateFilerSlice";

export const rootReducer = {
  SidebarVariables: SidbarVarSlice,
  ReferralVariables: referralVarSlice,
  JobManagementVariables: jobVarSlice,
  optionsSlice: optionsSlice,
  RoleBasedActions: roleBasedActionSlice,
  optionsSlice: optionsSlice,
  RefferedCandidatesVariable: refferedcandidateSlice,
  incentivePayoutsVariable: incentivePayoutsSlice,
  queryManagementVariable: queryManagementSlice,
  companyCategoriesVariable: companyCategoriesSlice,
  referredCandidatesFilters: referredCandidateFilerSlice,
};
