import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedFilter: {
    city_id: null,
    tier: null,
    referralStartRange: null,
    referralEndRange: null,
    incentiveEarnedMin: null,
    incentiveEarnedMax: null,
  },
};

const RefferedCandidatesVarSlice = createSlice({
  name: "reffredCandidateVarSlice",
  initialState,
  reducers: {
    setRefferedCandidateFilters: (state, action) => {
      state.selectedFilter = { ...state.selectedFilter, ...action.payload };
    },
    resetSelectedFilters: (state) => {
      state.selectedFilter = initialState.selectedFilter;
    },
  },
});

export const { setRefferedCandidateFilters, resetSelectedFilters } =
  RefferedCandidatesVarSlice.actions;

export default RefferedCandidatesVarSlice.reducer;
