import { Form, Select } from "antd";
import React from "react";

const CustomSelect = ({
  w,
  Label,
  name,
  options,
  isRequired,
  placeholder,
  setSearchedKeyword,
  handleChange,
  isDisabled,
  mode,
}) => {
  return (
    <div className={`${w ? w : "w-[49%]"}`}>
      <p className="text-grey mb-2 text-[0.875rem]">
        {Label}
        {isRequired && (
          <label>
            <span style={{ color: "red" }}> *</span>
          </label>
        )}
      </p>

      <Form.Item
        name={name}
        rules={
          isRequired && [
            {
              required: true,
              message: "Please Select!",
            },
          ]
        }
      >
        <Select
          showSearch
          size={"large"}
          placeholder={placeholder}
          onChange={handleChange}
          disabled={isDisabled}
          mode={mode}
          filterOption={(inputValue, option) =>
            option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
          }
          onSearch={(e) => {
            if (setSearchedKeyword) {
              setSearchedKeyword(e);
            }
          }}
        >
          {options?.map((data, index) => {
            // console.log(data)
            return (
              <Select.Option value={data.id|| data.value} key={index}>
                {data?.value?.length > 60
                  ? data?.value.slice(0, 60) + "..."
                  : data?.value}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
    </div>
  );
};

export default CustomSelect;
