import React, { useState } from "react";
import { coporateOnboardingColumns } from "../../Assests/Columns";
import { Table } from "antd";
import Search from "../../Components/Common/Search";
import { PlusOutlined } from "@ant-design/icons";
import CreateCoporateOnboardingModal from "../../Components/CoporateOnboarding/CreateCoporateOnboardingModal";

const CoporateOnboarding = () => {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [showCreateModal, setShowCreateModal] = useState(false);
  const data = [];
  return (
    <div className="p-4 pt-0">
      <div className="flex justify-between items-center bg-white p-4 mt-4">
        <p className="text-[1.25rem] font-semibold text-headingGrey">
          Coporate Onboarding
        </p>

        <div className="flex gap-6 items-center justify-center">
          <Search
            searchPlaceholder={"Search by center name"}
            setSearchKeyword={setSearchKeyword}
          />
          <button
            className="bg-blue text-white py-2 px-4 rounded-[4px] text-[0.875rem]"
            onClick={()=>setShowCreateModal(true)}
          >
            <PlusOutlined className="mr-2 " />
            Add Center
          </button>
        </div>
      </div>
      <Table
        columns={coporateOnboardingColumns}
        dataSource={data}
        // pagination={{
        //   total: count,
        //   pageSize: limit,
        //   showSizeChanger: false,
        //   onChange: (page) => setPageNumber(page),
        // }}
      />

      {showCreateModal && <CreateCoporateOnboardingModal closeModal={()=>setShowCreateModal(false)}/>}
    </div>
  );
};

export default CoporateOnboarding;
