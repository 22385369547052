import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../axios";

const initialState = {
  count: 0,
  companyCategoriesListing: [],
  isLoading: false,
  pageNumber: 1,
  isTableDataLoading: false,
};

export const getCompanyListing = createAsyncThunk(
  "getCompanyListing",
  async (args, { getState, rejectWithValue }) => {
    try {
      const state = getState(); // Access current state
      const { pageNumber } = state?.companyCategoriesVariable;

      const response = await axiosInstance.get(
        `/company?limit=${args.limit}&page=${pageNumber}&name=${args.search}`
      );
      if (response?.status === 200) {
        const data = response?.data?.data;
        return data;
      }
    } catch (error) {}
  }
);

export const deleteCompanyCategory = createAsyncThunk(
  "deleteCompanyCategory",
  async (args, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(
        `/company/archive/${args.companyId}`
      );
      if (response?.status === 200) {
        const data = response?.data?.data;
        return data;
      }
    } catch (error) {}
  }
);

const companyCategoriesSlice = createSlice({
  name: "companyCategoriesSlice",
  initialState,
  reducers: {
    setPageNumber: (state, action) => {
      state.pageNumber = action?.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCompanyListing.fulfilled, (state, action) => {
        state.companyCategoriesListing = [...action?.payload?.companies];
        state.count = action.payload?.count;
        state.isTableDataLoading = false;
      })
      .addCase(getCompanyListing.pending, (state, action) => {
        state.companyCategoriesListing = [];
        state.isTableDataLoading = true;
      })
      .addCase(getCompanyListing.rejected, (state, action) => {
        state.companyCategoriesListing = [];
        state.isTableDataLoading = false;
      });
  },
});

export const { setPageNumber } = companyCategoriesSlice.actions;

export default companyCategoriesSlice.reducer;
