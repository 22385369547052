import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../axios";
import { exportToCSV } from "../../../Assests/CommonFunction";

const initialState = {
  selectedFilter: {
    location: "",
    postedFrom: "",
    postedTo: "",
    status: "",
    search: "",
  },
  count: 0,
  queryManagementListing: [],
  isTableDataLoading: false,
  usersList: [],
  isLoading: false,
};

export const getQueryManagementListing = createAsyncThunk(
  "getQueryManagementListing",
  async (args, { getState, rejectWithValue }) => {
    try {
      const state = getState(); // Access current state
      const { search, status } = state?.queryManagementVariable?.selectedFilter;

      //   let params = {
      //     search: search,
      //     payment_status: status,
      //     start_date: postedFrom,
      //     end_date: postedTo,
      //     city_id: location,
      //   };

      // console.log("params", params);

      const response = await axiosInstance.get(
        `/query-management?search=${search}&query_status=${status}&limit=${args.limit}&page=${args.pageNumber}`
      );
      if (response?.status === 200) {
        const data = response?.data?.data;
        return data;
      }
    } catch (error) {}
  }
);

export const getQueryManagementListingDownload = createAsyncThunk(
  "getQueryManagementListingDownload",
  async (args, { getState, rejectWithValue }) => {
    try {
      const state = getState(); 
      const { search, status } = state?.queryManagementVariable?.selectedFilter;

      const response = await axiosInstance.get(
        `/query-management?search=${search}&query_status=${status}&limit=${args.limit}&page=${args.pageNumber}&download_csv=${args.download_csv}`
      );

      if (response?.status === 200) {
        exportToCSV(response?.data, "QueryManagement");
        return;
      }
    } catch (error) {}
  }
);

export const getQueryManagementCardsDetails = createAsyncThunk(
  "getQueryManagementCardsDetails",
  async (args, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/query-management/get-stats`);
      if (response?.status === 200) {
        const data = response?.data?.data;
        return data;
      }
    } catch (error) {}
  }
);

export const getQueryDetailsById = createAsyncThunk(
  "getQueryDetailsById",
  async (args, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `/query-management/${args.queryId}`
      );
      if (response?.status === 200) {
        const data = response?.data?.data;
        return data;
      }
    } catch (error) {}
  }
);

export const getUsersList = createAsyncThunk(
  "getUsersList",
  async (args, { rejectWithValue }) => {
    try {
      let response;
      if (args?.searchedKeyword) {
        response = await axiosInstance.get(
          `/mobilizer/admin?page=1&limit=10&search=${args.searchedKeyword}`
        );
      } else {
        response = await axiosInstance.get(`/mobilizer/admin?page=1&limit=10`);
      }

      if (response?.status === 200) {
        const data = response?.data?.data;
        return data;
      }
    } catch (error) {}
  }
);

export const addNewQuery = createAsyncThunk(
  "addNewQuery",
  async (args, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/query-management`, args);
      if (response?.status === 200) {
        const data = response?.data?.data;
        return data;
      }
    } catch (error) {}
  }
);

export const updateQuery = createAsyncThunk(
  "updateQuery",
  async (args, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.patch(`/query-management`, args);
      if (response?.status === 200) {
        const data = response?.data;
        return data;
      }
    } catch (error) {}
  }
);

const queryManagementSlice = createSlice({
  name: "queryManagementSlice",
  initialState,
  reducers: {
    setQueryFilters: (state, action) => {
      state.selectedFilter = { ...action?.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getQueryManagementListing.fulfilled, (state, action) => {
        state.queryManagementListing = [...action?.payload?.queries];
        state.count = action?.payload?.count?.count;
        state.isTableDataLoading = false;
      })
      .addCase(getQueryManagementListing.pending, (state, action) => {
        state.queryManagementListing = [];
        state.isTableDataLoading = true;
      })
      .addCase(getQueryManagementListing.rejected, (state, action) => {
        state.queryManagementListing = [];
        state.isTableDataLoading = false;
      })
      .addCase(getUsersList.fulfilled, (state, action) => {
        state.usersList = [...action?.payload?.rows];
      })
      .addCase(getUsersList.pending, (state, action) => {
        state.usersList = [];
      })
      .addCase(getUsersList.rejected, (state, action) => {
        state.usersList = [];
      })
      .addCase(getQueryDetailsById.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getQueryDetailsById.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getQueryDetailsById.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const { setQueryFilters } = queryManagementSlice.actions;

export default queryManagementSlice.reducer;
