import React from "react";
import OpenDropDown from "../../Assests/Images/OpenProfielDropdown.svg";
import { UserOutlined } from "@ant-design/icons";

const Header = () => {
  const roleInfo = JSON.parse(localStorage.getItem("roleInfo"));
  return (
    <div className="w-full h-[10vh] flex items-center justify-between border-b-2">
      <div className="w-full h-full flex justify-between items-center px-6">
        <div className="">
          <p className="font-semibold">Hi {roleInfo?.first_name}!</p>
          <p className="text-grey">Let’s check your Kadam today.</p>
        </div>
        <div className="flex justify-between items-center gap-3">
          {/* <img
            src=""
            alt=""
            className="bg-grey rounded-full w-[2.5rem] h-[2.5rem]"
          /> */}
          <div className="p-1 border-2 rounded-full h-10 w-10 flex items-center justify-center border-black">
            <UserOutlined />
          </div>
          <div>
            <p className="font-medium">{`${roleInfo?.first_name} ${roleInfo?.last_name}`}</p>
            <p className="text-grey text-[0.8rem] capitalize">
              {" "}
              {roleInfo?.name}
            </p>
          </div>
          {/* <img src={OpenDropDown} alt="" className="cursor-pointer" /> */}
        </div>
      </div>
    </div>
  );
};

export default Header;
