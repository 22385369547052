import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../axios";
import moment from "moment";
import { toast } from "react-toastify";
import { exportToCSV } from "../../../Assests/CommonFunction";

const initialState = {
  selectedFilter: {
    city_ids: [],
    startDate: "",
    endDate: "",
    status: "",
    search: "",
  },
  count: 0,
  incentivePayoutListing: [],
  isTableDataLoading: false,
};

export const getIncentivePayoutsListing = createAsyncThunk(
  "getIncentivePayoutsListing",
  async (args, { getState, rejectWithValue }) => {
    try {
      const state = getState(); // Access current state
      const {
         tiers=[],
         candidate_ids=[],
        search,
        status,
        startDate = "",
        endDate = "",
        city_ids = ""
      } = state?.incentivePayoutsVariable?.selectedFilter;
      
      let params = {
        tiers:tiers,
        user_ids:candidate_ids,
        search: search,
        payment_status: status,
        start_date: startDate ? moment(startDate).format("YYYY-MM-DD") : "",
        end_date: endDate ? moment(endDate).format("YYYY-MM-DD") : "",
        city_ids: city_ids.length === 0 ? "" : city_ids,
      };

      const response = await axiosInstance.post(
        `/incentive?limit=${args.limit}&page=${args.pageNumber}`,
        params
      );
      if (response?.status === 200) {
        const data = response?.data?.data;
        return data;
      }
    } catch (error) {
      // toast.error(error?.response?.data?.message);
      // throw error;
    }
  }
);
export const downloadIncentiveTemplateCSV = createAsyncThunk(
  "downloadIncentiveTemplateCSV",
  async (args, { getState, rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/incentive/download/incentive-template`);
      if (response?.status === 200) {
        const data = response?.data;
        exportToCSV(data?.data, "Incentive bulk upload template csv");
        toast.success("Bulk upload incentive template csv downloaded")
      }
    } catch (error) {
      toast.error("Error in downloading template csv")
      // toast.error(error?.response?.data?.message);
      // throw error;
    }
  }
);

export const downloadIcentive = createAsyncThunk(
  "downloadIncentive",
  async (args, { getState, rejectWithValue }) => {
    try {
      const state = getState(); // Access current state
      const {
        search,
        status,
        startDate = "",
        endDate = "",
        city_ids = "",
      } = state?.incentivePayoutsVariable?.selectedFilter;

      let params = {
        search: search,
        payment_status: status,
        start_date: startDate ? moment(startDate).format("YYYY-MM-DD") : "",
        end_date: endDate ? moment(endDate).format("YYYY-MM-DD") : "",
        city_ids: city_ids.length === 0 ? "" : city_ids,
        download_csv: true,
      };

      const response = await axiosInstance.post(`/incentive`, params);
      if (response?.status === 200) {
        const data = response?.data;
        exportToCSV(data, "Incetive Payouts");
      }
    } catch (error) {
      // toast.error(error?.response?.data?.message);
      // throw error;
    }
  }
);

export const getIncentivePayoutsCardsDetails = createAsyncThunk(
  "getIncentivePayoutsCardsDetails",
  async (args, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/incentive/get-stats`);
      if (response?.status === 200) {
        const data = response?.data?.data;
        return data;
      }
    } catch (error) {}
  }
);

export const postPayIncentive = createAsyncThunk(
  "postPayIncentive",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `/incentive/v2/pay-incentive`,
        data
      );
      if (response?.status === 200) {
        const responseData = response?.data?.data;
        return responseData;
      }
    } catch (error) {
      // Handle errors or reject with value
      return rejectWithValue(error.message);
    }
  }
);

export const getIncentiveStagesDetails = createAsyncThunk(
  "getIncentiveStagesDetails",
  async (args, { rejectWithValue }) => {
    // removed unused 'args' parameter
    try {
      const response = await axiosInstance.get(
        `/incentive/get-incentive-breakdown?user_id=${args.userId}&status=${args.status}`
      );
      if (response?.status === 200) {
        const responseData = response?.data?.data; // rename to avoid shadowing 'data'
        return responseData;
      }
    } catch (error) {
      // Handle errors or reject with value
      return rejectWithValue(error?.message);
    }
  }
);

export const getUserDetails = createAsyncThunk(
  "getUserDetails",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `/incentive/mobilizer-details/${userId}`
      );
      if (response?.status === 200) {
        const responseData = response?.data?.data; // rename to avoid shadowing 'data'
        return responseData;
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const incentivePayoutsSlice = createSlice({
  name: "incentivePayoutsSlice",
  initialState,
  reducers: {
    setIncentiveFilters: (state, action) => {
      state.selectedFilter = { ...action?.payload };
    },
    removeSelectedCity: (state, action) => {
      if (state.selectedFilter.city_ids?.length > 0) {
        state.selectedFilter.city_ids = state.selectedFilter.city_ids.filter(
          (item) => item !== action.payload
        );
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getIncentivePayoutsListing.fulfilled, (state, action) => {
        state.incentivePayoutListing = [...action?.payload?.incentives];
        state.count = action?.payload?.count[0]?.count;
        state.isTableDataLoading = false;
      })
      .addCase(getIncentivePayoutsListing.pending, (state, action) => {
        state.incentivePayoutListing = [];
        state.isTableDataLoading = true;
      })
      .addCase(getIncentivePayoutsListing.rejected, (state, action) => {
        state.incentivePayoutListing = [];
        state.isTableDataLoading = false;
      });
  },
});

export const { setIncentiveFilters, removeSelectedCity } =
  incentivePayoutsSlice.actions;

export default incentivePayoutsSlice.reducer;
