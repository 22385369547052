import React, { useEffect } from "react";
import CustomModal from "../Common/CustomModal";
import { DatePicker, Form } from "antd";
import CustomSelect from "../Common/CustomSelect";
import { useDispatch, useSelector } from "react-redux";
import {
  getCompanyOptions,
  getJobsCategory,
} from "../../redux/Slices/OptionsForSelect/optionsSlice";

const CreateCoporateOnboardingModal = ({ isEdit, closeModal }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { companies, category } = useSelector((state) => state.optionsSlice);

  useEffect(() => {
    if (!(companies?.length > 0)) {
      dispatch(getCompanyOptions());
    }
    if (!(category?.length > 0)) {
      dispatch(getJobsCategory());
    }
  }, [dispatch, companies, category]);

  const handleSubmit = (values) => {
    console.log(values, "kajdfkjadfh");
  };

  return (
    <CustomModal
      text={isEdit ? "Edit Corporate Onboarding" : "Add Corporate Onboarding"}
      showHeader={true}
      w={"w-[40%]"}
      onAction={closeModal}
    >
      <Form form={form} onFinish={handleSubmit}>
        <div className="flex w-full justify-between mt-4">
          <CustomSelect
            name={"company_id"}
            Label={"Company"}
            isRequired={true}
            placeholder={"Ex: Zomato"}
            options={companies}
          />
          <CustomSelect
            name={"category_id"}
            Label={"Job Category"}
            placeholder={"Ex: Security Guard"}
            options={category}
          />
        </div>
        <div className="flex w-full justify-between ">
          <div className="w-[49%]">
            <p className="text-grey mb-2 text-[0.875rem]">
              Valid From
              <label>
                <span style={{ color: "red" }}> *</span>
              </label>
            </p>
            <Form.Item
              name="validFrom"
              rules={[{ required: true, message: "Please select a date!" }]}
            >
              <DatePicker />
            </Form.Item>
          </div>

          <div className="w-[49%]">
            <p className="text-grey mb-2 text-[0.875rem]">Valid Till</p>
            <Form.Item name="validTill">
              <DatePicker />
            </Form.Item>
          </div>
        </div>

        <div className="flex w-full justify-start ">
          <button className=" rounded-[4px] bg-blue w-[8rem] py-2 text-white font-medium">
            {" "}
            Submit
          </button>
        </div>
      </Form>
    </CustomModal>
  );
};

export default CreateCoporateOnboardingModal;
