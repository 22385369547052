import { CloseOutlined } from "@ant-design/icons";
import React from "react";

const CustomModal = ({ children, onAction, text, showHeader, w }) => {
  return (
    <div className="bg-[#00000075] fixed w-full h-full items-center top-0 left-0 z-50 flex justify-center  ">
      <div className={`bg-white p-4 max-h-[90vh] overflow-y-scroll rounded-[8px] ${w ? w : "w-[45%]"} `}>
        {showHeader && (
          <div className="flex justify-between items-center w-full">
            <p className="text-[1.125rem] font-semibold">{text}</p>
            
            <CloseOutlined
              className="text-grey text-[1.2rem] cursor-pointer"
              onClick={onAction}
            />
          </div>
        )}
        {children}
      </div>
    </div>
  );
};

export default CustomModal;
