export const fetchToken = () => {
  if (localStorage.getItem("e_u_token")) {
    return localStorage.getItem("e_u_token");
  } else {
    return false;
  }
};

export const getFormattedDate = (inputDate) => {
  const date = new Date(inputDate);
  const formattedDateString = date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
  return formattedDateString;
};
export const  formatToINR=(amount)=> {
  // Using the Intl.NumberFormat with en-IN locale for Indian Rupee formatting
  return new Intl.NumberFormat('en-IN', {
    style: 'currency', // For currency formatting
    currency: 'INR',   // Indian Rupee symbol
    minimumFractionDigits: 2, // To ensure 2 decimal places
    maximumFractionDigits: 2
  }).format(amount);
}
export const getFilterFormatDate = (dateStr) => {
  if (!dateStr) return null;
  const parts = dateStr.split("-");
  if (parts.length !== 3) {
    throw new Error("Invalid date format");
  }
  const year = parts[0];
  const month = parts[1];
  const day = parts[2];
  return `${day}/${month}/${year}`;
};

export function convertDateFormat(dateString) {
  // Create a new Date object from the provided string
  const date = new Date(dateString);

  // Get the year, month, and day from the Date object
  const year = date.getFullYear();
  // Add 1 to month because getMonth() returns zero-based index
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");

  // Concatenate year, month, and day with '-' separator
  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
}

export function firstCharacterCapital(string) {
  const capitalizedString =
    string?.charAt(0).toUpperCase() + string?.slice(1).toLowerCase();

  return capitalizedString;
}

export const getStatusColour = (data) => {
  const colours = {
    SENT_REFERRAL: "bg-[#FAF9F8]",
    ON_WHATSAPP: "bg-[#F0F7FF]",
    ONLINE_TRAINING: "bg-[#F0F7FF]",
    OFFLINE_TRAINING: "bg-[#F0F7FF]",
    ONBOARDED: "bg-[#E8FFE1]",
    REJECTED: "bg-[#AD211F14]",
    REFERRAL_WITHDRAWN: "bg-[#AD211F14]",
  };

  return colours[data];
};

export const getTextStatusColour = (data) => {
  const colours = {
    SENT_REFERRAL: "text-[#4D4C4C]",
    ON_WHATSAPP: "text-[#1A80FF]",
    ONLINE_TRAINING: "text-[#1A80FF]",
    OFFLINE_TRAINING: "text-[#1A80FF]",
    ONBOARDED: "text-[#2CAF00]",
    REJECTED: "text-[#AD211F]",
    REFERRAL_WITHDRAWN: "text-[#AD211F]",
  };

  return colours[data];
};

export const downloadDocument = (e) => {
  // console.log(e.target.href , 'askdfhjaksfg')
  // e.preventDefault();
  fetch(e, {
    method: "GET",
    mode: "cors",
    headers: {},
  })
    .then((response) => {
      response.arrayBuffer().then(function (buffer) {
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "a.jpg");
        document.body.appendChild(link);
        link.click();
      });
    })
    .catch((err) => {});
};

// function to fetch the fields which has error in add and edit job

export const filterFields = (errors, locations, jobRequirements, stages) => {
  const allFields = {
    company_id: "Company",
    category_id: "Category",
    title: "Title",
    enrollment_cost: "Enrollment Cost",
    description_en: "English Description",
    description_hi: "Hindi Description",
  };

  locations?.map((a, index) => {
    allFields[`city_id${index}`] = `${index + 1} Location`;
    allFields[`working_shift${index}`] = `${index + 1} Working Shift`;
    allFields[`incentive${index}`] = `${index + 1} Incentive`;
    allFields[`total_positions${index}`] = `${index + 1} Total Positions`;
    allFields[`min_value${index}`] = `${index + 1} Min value`;
    allFields[`max_value${index}`] = `${index + 1} Max Value`;
  });
  stages.map((a, index) => {
    allFields[`stage${index}`] = `${index + 1} Stage`;
    allFields[`stageIncentive${index}`] = `${index + 1} Stage Incentive`;
  });

  jobRequirements.map((a, index) => {
    allFields[`job_requirements_en${index}`] = `${
      index + 1
    } English Job Requirement`;
    allFields[`job_requirements_hi${index}`] = `${
      index + 1
    } Hindi Job Requirement`;
  });

  const fieldsWithError = errors?.errorFields?.map((field) => {
    if (field?.errors?.length > 0) {
      return field?.name[0];
    }
  });

  const rectifiedErrorFields = fieldsWithError?.map((name) => allFields[name]);

  return rectifiedErrorFields;
};

export const filterJobCategoryErrorFields = (errors, jobRequirements) => {
  const allFields = {
    name_en: "Job Category English",
    name_hi: "Job Category Hindi",
    priority: "Priority",
  };
  jobRequirements.map((a, index) => {
    allFields[`job_requirements_en${index}`] = `${
      index + 1
    } English Job Requirement`;
    allFields[`job_requirements_hi${index}`] = `${
      index + 1
    } Hindi Job Requirement`;
  });

  const fieldsWithError = errors?.errorFields?.map((field) => {
    if (field?.errors?.length > 0) {
      return field?.name[0];
    }
  });

  const rectifiedErrorFields = fieldsWithError?.map((name) => allFields[name]);

  return rectifiedErrorFields;
};

export const exportToCSV = (data, filename) => {
  const csvData = data;
  const blob = new Blob([csvData], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  const now = new Date();
  const date = now.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
  const time = now.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });

  link.setAttribute(
    "download",
    `${filename ? filename : "data"}-${date}-${time}.csv`
  );
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(url);
};

export const filterFaqErrorFields = (errors) => {
  const allFields = {
    question_en: "English Question",
    answer_en: "English Answer",
    question_hi: "Hindi Question",
    answer_hi: "Hindi Answer",
    category_id: "Category",
    video_url: "Video Link",
  };

  const fieldsWithError = errors?.errorFields?.map((field) => {
    if (field?.errors?.length > 0) {
      return field?.name[0];
    }
  });

  const rectifiedErrorFields = fieldsWithError?.map((name) => allFields[name]);

  return rectifiedErrorFields;
};
