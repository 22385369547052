import React from "react";

const TermsAndConditions = () => {
  return (
    <div>
      <div className="flex items-center justify-center py-6 bg-[#0067E5] text-white">
        <h1 className="text-[1.2rem]">TERMS OF USE</h1>
      </div>
      <div className="px-10 py-4">
        <h2 className="font-semibold text-[1.2rem] mt-4">1. BACKGROUND</h2>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          1.1. <b>Kadam Mobility</b>, a private limited company incorporated in
          Gurugram, Haryana, India (<b>“Kadam”</b>, "us", "we", or "our"),
          provides recruitment services through its website accessible at
          <b> www.kadammobility.com </b>(the “Website”) and a WhatsApp
          Application based mobile application called <b>Kadam App</b>{" "}
          ("Application"). The services provided by us through the Website and
          the Application shall be known as the “Services”. Any reference to
          “you” or “User” <b>or the “Customer”</b> shall mean you, the user of
          the Services. <b>Kadam</b> and you shall collectively be known as the
          “Parties” for the purposes of these Terms of Use (“Terms”).
        </p>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          1.2. Your access to and use of the Services is conditioned on your
          acceptance of and compliance with these Terms. These Terms apply to
          all visitors, users and others who access or use the Services. By
          accessing or using the Service you agree to be bound by these Terms.
          If you disagree with any part of these Terms then you may not access
          the Services and must immediately stop using the Website and the
          Application.
        </p>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          1.3. Since these Terms constitute a legal contract between Kadam and
          you, it is imperative that you review these Terms carefully and in
          their entirety. These Terms shall be read along with Kadam’s Privacy
          Policy (“Privacy Policy”) available at{" "}
          <b>Web Link of the Privacy Policy</b> which shall be and read to be a
          part of these Terms.
        </p>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          1.4. We reserve the right to make changes to these Terms at any time.
          Any such modifications shall become effective immediately upon the
          update being posted on this page and shall constitute effective
          communication of the same to you You agree to periodically review the
          updated version of these Terms whereby your continued use shall
          constitute acceptance of these Terms and their modifications If a
          revision is material we will try to (but are not obligated to) provide
          at least 30 days’ notice prior to any new terms taking effect. What
          constitutes a material change will be determined at our sole
          discretion.
        </p>
        <h2 className="font-semibold text-[1.2rem] mt-4">2. ACCOUNTS</h2>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          2.1. When you avail our Services, including but not limited to
          creating an account with us, you must provide us information that is
          accurate, complete, and current at all times. Failure to do so
          constitutes a material breach of the Terms, which may result in the
          immediate termination of your access to our Services at our sole
          discretion.
        </p>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          A. The Services are available only to persons above the age of 18
          (Eighteen) years. If you are under the age of 18 (Eighteen), you may
          not avail our services including, but not limited to, creating an
          account with us. We reserve the right to deny access to the Services
          if it is brought to our notice that you are under the age of 18
          (Eighteen) and are accessing the Services. We disclaim all liabilities
          for any harm caused to you on account of using the Services in
          violation of this provision.
        </p>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          2.2. You must notify us immediately upon becoming aware of any breach
          of security or unauthorized use of our Services through your device,
          Application, phone number, or in your name or likeness. We shall take
          no responsibility on account of any liabilities incurred as a
          consequence of misuse of your device, Application, phone number, or in
          your name or likeness.
        </p>
        <h2 className="font-semibold text-[1.2rem] mt-4">3. CONSENT</h2>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          3.1. You agree and consent to receive all communications related to
          the Services at the mobile number provided by you to Kadam, even if
          such mobile number is registered under DND/NCPR list under the Telecom
          Commercial Communications Customer Preference Regulations, 2018 (“TRAI
          Regulations”).
        </p>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          3.2. Notwithstanding your registration with the National Do Not Call
          Registry (In Fully or Partly blocked category under National Customer
          Preference Register set up under the Telecom Regulatory Authority of
          India), you hereby express your interest and accord informed consent
          to receive communications (including commercial communications) in
          relation to the Services.
        </p>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          3.3. You further confirm that no communication to you from Kadam or on
          behalf of Kadam shall be construed as Unsolicited Commercial
          Communication under Regulation 2(bw) of the TRAI Regulations and you
          have specifically opted to receive communications in this regard on
          the mobile number provided by you.
        </p>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          3.4. You further authorize Kadam to share/disclose any information
          provided by you, to any third party service provider or any
          affiliates, group companies, their authorised agents or third party
          service providers, and such disclosure shall be governed by the terms
          of our Privacy Policy where it is ‘personal information’ within the
          meaning of the Information Technology (Reasonable Security Practices
          and Procedures and Sensitive Personal Data or Information) Rules, 2011
        </p>
        <h2 className="font-semibold text-[1.2rem] mt-4">4. PAYMENT</h2>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          4.1. Kadam reserves its right to revise the charges for Services, at
          any time, without the requirement of any prior intimation to the User.
          Any such change shall be binding and effective on the User.
        </p>
        <h2 className="font-semibold text-[1.2rem] mt-4">
          5. LINKS TO OTHER WEB SITES
        </h2>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          5.1. The Services may provide links to other websites ("Third Party
          Links"). The Third Party Links are not under the control of Kadam. We
          are not responsible for the content of any Third Party Links,
          including, without limitation, any link contained in a Third Party
          Linked Site.
        </p>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          5.2. We are not responsible for any form of transmission whatsoever,
          received by the User from any Third-Party Links. We are providing
          these links only for your reference, and the inclusion of any such
          link does not imply endorsement by Kadam, of the Third-Party Links or
          any association with its operators or owners including the legal heirs
          or assigns thereof. The appearance of a link does not constitute an
          endorsement, recommendation or certification by us, nor should the
          presence of a link in any way be construed as a suggestion that any
          third-party website has any relationship to Kadam. Kadam does not
          endorse the content on any third-party websites and is not responsible
          for the content of linked third-party websites or third-party
          advertisements and does not make any representations regarding such
          third-party’s content and/or accuracy. Kadam does not knowingly link
          to websites that may infringe on valid and existing trademarks,
          service marks, copyrights or patents or be in violation of Applicable
          Law.
        </p>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          5.3. On accessing the Third-Party Links, you shall be governed by the
          terms of use, privacy policy, and such other additional policies of
          the Third-Party Links. You are hereby advised to peruse the same. You
          further acknowledge and agree that Kadam shall not be responsible or
          liable, directly or indirectly, for any damage or loss caused or
          alleged to be caused by or in connection with the use of or reliance
          on any such content, advertising, products, services, or other
          materials available on or through any Third Party Links or for any
          errors, defamatory content, libel, slander, omissions, falsehoods,
          obscene content, pornographic material, breach of any Applicable Law,
          or any profanity contained therein.
        </p>
        <h2 className="font-semibold text-[1.2rem] mt-4">
          6. INTELLECTUAL PROPERTY RIGHTS
        </h2>
        <h3>6.1. Kadam Content</h3>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          6.1.1. The copyrights, design rights (whether or not registered),
          moral rights, performance rights, database rights, new media rights,
          names, logos, publicity rights, and any and all other intellectual
          property and proprietary rights of any nature whatsoever that subsist,
          or may subsist, or be capable of registration, in each case in
          relation to the creative work of the author of the content available
          on the Services, rests solely, exclusively, and irrevocably with
          Kadam.
        </p>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          6.1.2. Except as provided otherwise through the Services, all Kadam
          content available on the Services, including but not limited to, text,
          graphics, logos, designs, photographs, button icons, images, video
          clips, digital downloads, data compilations etc., is our property and
          is protected by the applicable laws with respect to intellectual
          property rights. The compilation of all content on these Services is
          our exclusive property, and shall not be reproduced or used without
          express written permission from us. We reserve the right to terminate
          your engagement with us, if we, in our sole and absolute discretion,
          believe that you are in violation of this clause.
        </p>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          6.1.3. The content made available on or via the Services, is provided
          to you ‘AS IS’ for your information and personal use only and may not
          be used, copied, reproduced, distributed, transmitted, broadcast,
          displayed, sold, licensed, or otherwise exploited for any other
          purposes whatsoever without our prior written consent. We reserve all
          rights, not expressly granted in and to the Services.
        </p>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          6.1.4 All Intellectual Property in content used on the Services
          sourced from any third party resides with and shall continue to reside
          with such third party.
        </p>
        <h3>6.2. User Content</h3>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          While submitting or posting any content during the course of your
          engagement with our Services (“User Content”), the User agrees and
          acknowledges that:
        </p>
        <ul>
          <li>
            6.2.1 The User Content does not contain any confidential information
            and is not in violation of any third party right including
            intellectual property rights.
          </li>
          <li>
            6.2.2 The User Content shall not be unlawful, obscene, defamatory,
            libelous, threatening, pornographic, harassing, hateful, racially or
            ethnically offensive, or otherwise inappropriate; and
          </li>
          <li>
            6.2.3 We are not under any obligation of confidentiality, express or
            implied, regarding the User Content.
          </li>
        </ul>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          6.3. We reserve the right to use or disclose such User Content for any
          purpose, in any way, as we deem fit, in accordance with applicable
          law.
        </p>
        <h2 className="font-semibold text-[1.2rem] mt-4">
          7. DISCLAIMERS AND WARRANTIES
        </h2>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          7.1. To the maximum extent permitted by applicable law, the Services
          are provided without warranties of any kind, whether express or
          implied, and Kadam hereby disclaims and negates all other warranties,
          including without limitation, implied warranties or conditions of
          merchantability, fitness for a particular purpose, or non-infringement
          of intellectual property or other violation of rights.
        </p>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          7.2. The materials available through our Services are provided "AS
          IS". Kadam does not warrant or make any representations concerning the
          accuracy, likely results, or reliability of the use of the materials
          on our Services or otherwise relating to such materials or on any
          sites linked to the Services.
        </p>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          7.3. No information, whether oral or written, obtained by you as part
          of the Services will create any warranty not expressly stated herein.
          Without limiting the foregoing, Kadam specifically disclaims
          warranties pertaining to the following:
        </p>
        <ul>
          <li>
            7.3.1. That the content provided on or through the Services is
            accurate, reliable, complete, current or correct. And that such
            content does not include any technical, typographical, or
            photographic errors.
          </li>
          <li>
            7.3.2. That the content provided on or through the Services will not
            be modified or updated at any time without notice.
          </li>
          <li>7.3.3. That the Services will meet your requirements.</li>
          <li>
            7.3.4. That the Services will be available at any particular time or
            location, uninterrupted or secure.
          </li>
          <li>7.3.5. That any defects or errors will be corrected; or</li>
          <li>
            7.3.6. That the Services are free of viruses or other harmful
            components.
          </li>
        </ul>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          7.4. Any content downloaded or otherwise obtained through the use of
          the Services is downloaded at your own risk and you will be solely
          responsible for any damage to your computer system or mobile device or
          loss of data that results from such download or such use of the
          Services.
        </p>
        7.5. We do not warrant, endorse, guarantee, or assume responsibility for
        any product or service advertised or offered by a third party through
        the Services or any other hyperlinked website or service. Kadam will not
        be a party to or in any way monitor any transaction between you and
        third-party providers of products or services.
        <h2 className="font-semibold text-[1.2rem] mt-4">8. TERMINATION</h2>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          8.1. We may terminate or suspend access to our Services immediately,
          without prior notice or liability, for any reason whatsoever,
          including without limitation, where you breach these Terms. In
          addition to any termination rights, we reserve the right to enforce
          and prosecute any violations of these Terms.
        </p>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          8.2. All provisions of these Terms which by their nature should
          survive termination shall survive termination, including, without
          limitation, ownership provisions, warranty disclaimers, indemnity and
          limitations of liability.
        </p>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          8.3. Upon termination, your right to access or use the Services will
          immediately cease. If you wish to terminate using our services, you
          may send a message or whatsapp message containing the text ‘STOP’ to
          us anytime on the number ____________ or send an email to us with this
          request on ___________.
        </p>
        <h2 className="font-semibold text-[1.2rem] mt-4">9. INDEMNIFICATION</h2>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          9.1. You shall indemnify and hold us harmless from and against any and
          all losses, damages, settlements, liabilities, costs, charges,
          assessments and expenses, as well as third party claims and causes of
          action, including, without limitation, attorneys’ fees, arising out of
          any breach by you of any of these Terms, or any use by you of the
          Services violates any third-party rights, including third party
          intellectual property rights or applicable laws.
        </p>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          9.2. You shall provide us with such assistance, without charge, as we
          may request in connection with any such defence, including, without
          limitation, providing us with such information, documents, records and
          reasonable access to you, as we deem necessary.
        </p>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          9.3. You shall not settle any third-party claim or waive any defence
          without our prior written consent.
        </p>
        <h2 className="font-semibold text-[1.2rem] mt-4">
          10. LIMITATION OF LIABILITY
        </h2>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          10.1.Kadam and its affiliates shall in no way be liable for any
          direct, incidental, consequential, indirect, special or punitive
          damages arising out of your access, use, misuse or inability to use
          the Services or any linked sites, or in connection with any failure of
          performance. These limitations apply whether the alleged liability is
          based on contract, tort, negligence, strict liability or any other
          basis, even if Kadam has been advised of such damage.
        </p>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          10.2. By use of the Services, you acknowledge that you are solely
          responsible for any and all actions, liabilities, consequences,
          decisions or behaviours arising out of or in connection with the use
          of the Services and shall in no way hold Kadam and/or its affiliates
          responsible for such conduct.
        </p>
        <h2 className="font-semibold text-[1.2rem] mt-4">
          11. GOVERNING LAW AND JURISDICTION
        </h2>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          These Terms shall be governed and construed in accordance with the
          laws of India, without regard to its conflict of law provisions.
          Courts in Gurugram, Haryana, India shall have exclusive jurisdiction
          over all disputes arising out of these Terms. You agree to waive any
          and all objections to the exercise of jurisdiction by such courts and
          to venue in such courts.
        </p>
        <h2 className="font-semibold text-[1.2rem] mt-4">
          12. MISCELLANEOUS PROVISIONS
        </h2>
        <h3>12.1. Entire Agreement:</h3>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          Unless otherwise specified herein, these Terms constitute the entire
          agreement between you and Kadam in respect of the Services and
          supersedes all previous written and oral agreements between you and
          Kadam, if any.
        </p>
        <h3>12.2. Unenforceability:</h3>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          If any provision of these Terms or any word, phrase, clause, sentence,
          or other portion thereof should be held to be unenforceable or invalid
          for any reason, then provided that the essential consideration for
          entering into these Terms for either you or Kadam is not unreasonably
          impaired, such provision or portion thereof shall be modified or
          deleted in such manner as to render these Terms as modified legal and
          enforceable to the maximum extent permissible under applicable laws.
        </p>
        <h3>12.3. Severability:</h3>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          If any term or provision in these Terms is held to be either illegal
          or unenforceable, in whole or in part, under any enactment or rule of
          law, such term or provision or part shall to that extent be deemed not
          to form part of these Terms, but the validity and enforceability of
          the remainder of these Terms shall not be affected.
        </p>
        <h3>12.4. Notices:</h3>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          Any notice required or permitted to be given to Kadam hereunder shall
          be in writing and sent or transmitted by (i) registered or certified
          mail; (ii) hand-delivery; (iii) email; or (iv) internationally
          recognized courier service, provided its receipt is acknowledged and,
          dispatched or sent or transmitted to the address specified by Kadam.
          All Notices required to be given under these Terms shall be addressed
          to:
          <p className="text-[0.9rem] py-1 text-headingGrey">Name:</p>
          <p className="text-[0.9rem] py-1 text-headingGrey">Postal Address:</p>
          <p className="text-[0.9rem] py-1 text-headingGrey">E-mail Address:</p>
        </p>
        <h3>12.5. No Agency:</h3>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          Nothing in this Agreement shall be construed as making either Party
          the partner, joint venture, agent, legal representative, employer or
          employee of the other. Neither Party shall have, or hold itself out to
          any third party as having any authority to make any statements,
          representations or commitments of any kind, or to take any action that
          shall be binding on the other, except as provided for herein or
          authorized in writing by the party to be bound.
        </p>
        <h3>12.6. No Waiver:</h3>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          No delay or omission by either Party hereto to exercise any right or
          power occurring upon any noncompliance or default by the other Party
          with respect to any of the terms of these Terms shall impair any such
          right or power or be construed to be a waiver thereof. The terms and
          conditions of these Terms may be waived or amended only in writing or
          mutual agreement of the Parties. A waiver by either of the Parties
          hereto of any of the covenants, conditions, or agreements to be
          performed by the other shall not be construed to be a waiver of any
          succeeding breach thereof or of any covenant, condition, or agreement
          herein contained (whether or not the provision is similar).
        </p>
        <h3>12.7. Assignment:</h3>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          This Agreement will be binding and will inure to the benefit of the
          legal representatives, successors and assigns of the Parties hereto.
        </p>
        <h3>12.8. Amendment:</h3>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          No amendment to this Agreement will be effective unless made in
          writing. The paragraph headings herein are solely for the sake of
          convenience and will not be applied in the interpretation hereof.
        </p>
        <h3>12.9. Grievance Redressal</h3>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          If you have any grievances against Kadam or its affiliates that stem
          from these Terms or our Privacy Policy, you may contact our Grievance
          Officer as per details below:
          <p className="text-[0.9rem] py-1 text-headingGrey">Name:</p>
          <p className="text-[0.9rem] py-1 text-headingGrey">Phone No:</p>
          <p className="text-[0.9rem] py-1 text-headingGrey">Email address:</p>
          <p className="text-[0.9rem] py-1 text-headingGrey">Postal address:</p>
        </p>
        <h3>12.10. Contact Us</h3>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          If you have any questions about these Terms, please contact us at:
        </p>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          Name:________; Email: _______________ .
        </p>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          12.11. To submit a request to delete your account or data, please get
          in touch at:
        </p>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          Name: _______________; Email: _____________.
        </p>
      </div>
    </div>
  );
};

export default TermsAndConditions;
