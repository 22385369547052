import { SearchOutlined } from "@ant-design/icons";
import React, { useState, useCallback, useRef, useEffect } from "react";

const debounce = (func, delay) => {
  let timeoutId;
  return function (...args) {
    const context = this;
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(context, args);
    }, delay);
  };
};

const Search = ({ setSearchKeyword, searchPlaceholder, w ,alwaysVisible=false}) => {
  const [isSearching, setIsSearching] = useState(alwaysVisible);
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef(null);

  useEffect(() => {
    if (isSearching) {
      inputRef.current.focus();
    }
  }, [isSearching]);

  const debouncedHandleInputChange = useCallback(
    debounce((value) => {
      setSearchKeyword(value);
    }, 350),
    []
  );

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    debouncedHandleInputChange(e.target.value);
  };

  return (
    <div>
      {isSearching ? (
        <div
          className={`flex  items-center rounded border-[#D9D9D9] border-[1.5px] h-full px-3 py-1 text-[0.9rem]  ${
            w ? w : "w-[22rem]"
          }`}
        >
          <SearchOutlined className="font-thin text-ctagrey" />
          <input
            ref={inputRef}
            type="text"
            placeholder={searchPlaceholder}
            className="p-2 outline-none h-6 w-full"
            value={inputValue}
            onChange={handleInputChange}
          />
        </div>
      ) : (
        <div
          className="flex items-center gap-2 justify-between cursor-pointer text-ctagrey font-medium"
          onClick={() => {
            setIsSearching(true);
          }}
        >
          <SearchOutlined className="font-thin" /> Search
        </div>
      )}
    </div>
  );
};

export default Search;
