import { Tooltip } from "antd";
import { firstCharacterCapital } from "./CommonFunction";
import { Link } from "react-router-dom";

export const jobManagementColumn = (isDelete, isWrite) => {
  const columns = [
    {
      title: "Job Id",
      dataIndex: "jobId",
      key: "jobId",
    },
    {
      title: "Job Category",
      dataIndex: "jobCategory",
      key: "jobCategory",
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "Total Openings",
      dataIndex: "filledTotalOpenings",
      key: "filledTotalOpenings",
      align: "center",
    },
    {
      title: "Posted On",
      dataIndex: "postedOn",
      key: "postedOn",
    },
  ];

  if (isDelete || isWrite) {
    columns.push({
      title: "Action",
      dataIndex: "action",
      key: "action",
    });
  }

  return columns;
};

export const referralManagementColoumn = [
  {
    title: "Referrence Id",
    dataIndex: "referrenceId",
    key: "referrenceId",
  },
  {
    title: "Job Category",
    dataIndex: "jobCategory",
    key: "jobCategory",
  },
  {
    title: "Company",
    dataIndex: "company",
    key: "company",
  },
  {
    title: "Candidate Name",
    dataIndex: "candidateName",
    key: "candidateName",
  },
  {
    title: "Mobilizer",
    dataIndex: "mobilizer",
    key: "mobilizer",
    render: (text, record) => {
      return (
        <Link
          to={`/mobilizer-management/reffered-candidates?${record?.userId}`}
          className="underline"
        >
          {text}
        </Link>
      );
    },
  },
  {
    title: "Location",
    dataIndex: "location",
    key: "location",
  },
  {
    title: "Referred On",
    dataIndex: "referredOn",
    key: "referredOn",
  },
  {
    title: "Last Status Updated At",
    dataIndex: "status_change_at",
    key: "status_change_at",
  },
  {
    title: "LOI Number",
    dataIndex: "loiNumber",
    key: "loiNumber",
    render: (loiNumber)=>{
      if(loiNumber===""||loiNumber===undefined)
        return <span  title={loiNumber} className="overflow-x-auto whitespace-nowrap w-[10px]">-</span>;
      else if(loiNumber.length>3)
        return  <span  title={loiNumber} className="overflow-x-auto whitespace-nowrap w-[10px]">{`${loiNumber.substring(0,3)}...`}</span>;
      else
      return  <span  title={loiNumber} className="overflow-x-auto whitespace-nowrap w-[10px]">{loiNumber}</span>}
},
  {
    title: "Cloak ID",
    dataIndex: "clockId",
    key: "clockId",
    render: (clockId)=>{
      if(clockId===""||clockId===undefined)
        return <span  title={clockId} className="overflow-x-auto whitespace-nowrap w-[10px]">-</span>;
      else if(clockId.length>3)
        return  <span  title={clockId} className="overflow-x-auto whitespace-nowrap w-[10px]">{`${clockId.substring(0,3)}...`}</span>;
      else
      return  <span  title={clockId} className="overflow-x-auto whitespace-nowrap w-[10px]">{clockId}</span>}  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    align: "center",
  },
];

export const queryManagementColoumn = [
  {
    title: "Query Id",
    dataIndex: "queryId",
    key: "queryId",
  },
  {
    title: "Raised By",
    dataIndex: "raisedBy",
    key: "raisedBy",
  },
  {
    title: "Phone No.",
    dataIndex: "phoneNo",
    key: "phoneNo",
  },
  {
    title: "Query Type",
    dataIndex: "queryType",
    key: "queryType",
  },
  {
    title: "Raised On",
    dataIndex: "raisedOn",
    key: "raisedOn",
    sorter: (a, b) => new Date(a.raisedOn) - new Date(b.raisedOn), // sorter function for sorting
  },
  {
    title: "Comment",
    dataIndex: "comment",
    key: "comment",
    width: "15%",
    render: (text) => {
      const maxLength = 50; // Maximum characters before truncation
      if (text?.length > maxLength) {
        const truncatedText = text.substring(0, maxLength) + "..."; // Truncate text if it exceeds maxLength
        return (
          <Tooltip title={text}>
            <span>{truncatedText}</span>
          </Tooltip>
        );
      } else {
        return (
          <Tooltip title={text}>
            <span>{text}</span>
          </Tooltip>
        );
      }
    },
  },
];

export const incentivePayoutsColumn = (isDelete, isWrite) => {
  const columns = [
    {
      title: "Mobilizer Name",
      dataIndex: "mobilizerName",
      key: "mobilizerName",
      sorter: (a, b) => a.mobilizerName.localeCompare(b.mobilizerName),

      render: (text) => {
        return <span>{text}</span>;
      },
    },
    {
      title: "Phone No.",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Due Payout",
      dataIndex: "duePayout",
      key: "duePayout",
    },
    {
      title: "Total Payout",
      dataIndex: "paidPayout",
      key: "paidPayout",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      sorter: (a, b) => a.location.localeCompare(b.location),
    },
    {
      title: "Referrel Ids",
      dataIndex: "referrelIds",
      key: "referrelIds",
      render: (referrelIds) => {
        if (Array.isArray(referrelIds) && referrelIds.length > 1) {
          return (
            <Tooltip 
              title={
                <div style={{ maxHeight: '200px',bottom:'20px', overflowY: 'scroll' }}>
                  {referrelIds.join(", ")}
                </div>
              } 
              overlayStyle={{ maxHeight: '30px', overflowY: 'auto' }} // Adjust the fixed height here
            >
              <>
                {referrelIds[0]} |{" "}
                <span style={{ color: '#1A80FF' }}>
                  +{referrelIds?.length - 1} more
                </span>
              </>
            </Tooltip>
          );
        } else {
          return referrelIds[0];
        }
      },
    },

    {
      title: "Tier",
      dataIndex: "tier",
      key: "tier",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => {
        const statusStyle = {
          color: text === "PAID" ? "#1A80FF" : "#F5970A", // Define colors based on status
          padding: "6px 12px",
          radius: "4px",
          backgroundColor: text === "PAID" ? "#F0F7FF" : "#FEF5E7",
        };
        return <span style={statusStyle}>{firstCharacterCapital(text)}</span>;
      },
    },
  ];

  if (isDelete || isWrite) {
    columns.push({
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text) => {
        return (
          <span className="text-[#1A80FF] underline text-[14px]">{text}</span>
        );
      },
    });
  }

  return columns;
};

export const trainingandFaqcolumns = (isDelete, isWrite) => {
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Video Link",
      dataIndex: "videolink",
      key: "videolink",
    },
  ];

  if (isDelete || isWrite) {
    columns.push({
      title: "Action",
      dataIndex: "action",
      key: "action",
    });
  }

  return columns;
};

export const RefferedCandidatesColumns = [
  {
    title: "Candidate Name",
    dataIndex: "candidateName",
    key: "candidateName",
  },
  {
    title: "Referrence Id",
    dataIndex: "referrenceId",
    key: "referrenceId",
  },
  {
    title: "Referred At",
    dataIndex: "company",
    key: "company",
  },
  {
    title: "Job Location",
    dataIndex: "location",
    key: "location",
  },

  {
    title: "Referred On",
    dataIndex: "referredOn",
    key: "referredOn",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    align: "center",
  },
];

export const CenterManagementColumns = (isDelete, isWrite) => {
  const columns = [
    {
      title: "Center Name",
      dataIndex: "centerName",
      key: "name",
    },
    {
      title: "Center Admin",
      dataIndex: "centerAdmin",
      key: "phone",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
    ,
  ];
  if (isDelete || isWrite) {
    columns.push({
      title: "Action",
      dataIndex: "action",
      key: "action",
    });
  }
  return columns;
};

export const SubAdminColumn = [
  {
    title: "Name",
    dataIndex: "Name",
    key: "name",
  },
  {
    title: "Phone No.",
    dataIndex: "PhoneNumber",
    key: "phone_no",
  },
  {
    title: "Email Id",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Access Level",
    dataIndex: "Accesslevel",
    key: "access_level",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
  },
];
// sr no. | mobiliser list | message | created at | send at 
export const BulkNotificationColumn = [
  {
    title: "Sr No.",
    dataIndex: "SerialNumber",
    key: "serial_number",
  },
  {
    title: "Mobiliser List",
    dataIndex: "MobiliserList",
    key: "mobiliser_list",
  },
  {
    title: "Message",
    dataIndex: "Message",
    key: "message",
  },
  {
    title: "Created At",
    dataIndex: "CreatedAt",
    key: "created_at",
  }
];


export const coporateOnboardingColumns = [
  {
    title: "Company",
    dataIndex: "company",
    key: "company",
  },
  {
    title: "Job Category",
    dataIndex: "jobCategory",
    key: "jobCategory",
  },
  {
    title: "Valid From",
    dataIndex: "validFrom",
    key: "validFrom",
  },
  {
    title: "Valid Till",
    dataIndex: "validTill",
    key: "validTill",
  },
  {
    title: "Fronted Link",
    dataIndex: "frontendLink",
    key: "frontendLink",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
  },
];
