import axios from "axios";
import { toast } from "react-toastify";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("e_u_token");
    config.headers["x-api-key"] = process.env.REACT_APP_X_API_KEY;
    config.headers["Accept-Language"] = "en_UK";
    config.headers["ngrok-skip-browser-warning"] = `*`;
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async function (error) {
    const originalRequest = error.config;

    if (error?.response?.status === 401 && !originalRequest._retry) {
      localStorage.clear();
      window.location.href = "/login";
    } else {
      toast.error(error?.response?.data?.message);
    }

    return Promise.reject(error);
  }
);
