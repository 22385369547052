import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="">
      <div className="flex items-center justify-center py-6 bg-[#0067E5] text-white">
        <h1 className="text-[1.2rem]">Kadam Privacy Policy</h1>
      </div>
      <div className="px-10 py-4">
        {/* <p className="text-[0.8rem]">
          THIS DOCUMENT IS AN ELECTRONIC RECORD IN TERMS OF INFORMATION
          TECHNOLOGY ACT, 2000 AND RULES THERE UNDER AS APPLICABLE AND THE
          AMENDED PROVISIONS PERTAINING TO ELECTRONIC RECORDS IN VARIOUS
          STATUTES AS AMENDED BY THE INFORMATION TECHNOLOGY ACT, 2000. THIS
          ELECTRONIC RECORD IS GENERATED BY A COMPUTER SYSTEM AND DOES NOT
          REQUIRE ANY PHYSICAL OR DIGITAL SIGNATURES.
        </p>
        <p className="text-[0.8rem] mt-2">
          THIS DOCUMENT IS PUBLISHED IN ACCORDANCE WITH THE PROVISIONS OF RULE 3
          (1) OF THE INFORMATION TECHNOLOGY (INTERMEDIARIES GUIDELINES) RULES,
          2011 THAT REQUIRE PUBLISHING THE RULES AND REGULATIONS, TERMS OF
          SERVICES, PRIVACY POLICY AND USER AGREEMENT FOR ACCESS OR TERMS OF
          USAGE OF www.kadammobility.com (HEREINAFTER REFERRED TO AS THE
          “Website”).
        </p> */}
        <BackgroundSection />
        <ConsentSection />
        <CollectionInformationSection />
        <UseOfInformationSection />
        <StorageOfInformationSection />
        <DisclosureAndSharingSection />
        <InformationRetentionSection />
        <GoverningLawSection />
        <ContactSection />
      </div>
    </div>
  );
};

const BackgroundSection = () => {
  return (
    <div>
      <h2 className="font-semibold text-[1.2rem] mt-4">1. Background</h2>
      <p className="text-[0.9rem] py-1 text-headingGrey">
        1.1. Kadam Mobility, a private limited company incorporated in Gurugram,
        Haryana, India <b> ("Kadam", "us", "we", or "our")</b> provides
        recruitment services through its website accessible at{" "}
        <a href="www.kadammobility.com" className="text-[#0067E5] underline">
          the "Website"
        </a>{" "}
        and a Whatsapp Messenger based mobile application called Kadam App
        <b>("Application")</b>. The services provided by us through the Website
        and Application shall be known as the <b> "Services"</b>. Any reference
        to <b>"you"</b> or <b>"User"</b> or the <b>"Customer"</b> shall mean
        you, the user of the Services. Kadam and you shall collectively be known
        as the <b>"Parties"</b> for the purposes of this privacy policy.
      </p>
      <p className="text-[0.9rem] py-1 text-headingGrey">
        1.2. Your privacy is very important to us. We respect your privacy and
        believe that we should clearly and transparently disclose our privacy
        practices to you. This privacy policy (the <b>"Privacy Policy"</b>)
        explains our policies regarding collection, use, and disclosure of your
        Personal Information (as defined in the SPDI Rules) and other data
        collected from you when you avail the Services offered by Kadam. This
        Privacy Policy does not apply to any third-party websites or
        applications. Please note that you shall visit any third-party sites and
        apps at your own risk upon conditions laid down in such third-party
        sites’ or apps’ respective terms of use and privacy policies.
      </p>
      <p className="text-[0.9rem] py-1 text-headingGrey">
        For the purpose of this Privacy Policy, <b>“SPDI Rules”</b> shall mean
        the Information Technology (Reasonable Security Practices and Procedures
        and Sensitive Personal Data or Information) Rules, 2011.
      </p>
      <p className="text-[0.9rem] py-1 text-headingGrey">
        1.3. We may change, modify, amend, terminate or replace this Privacy
        Policy at any time at our discretion. If we change, modify, amend or
        terminate this Privacy Policy, such modifications will become effective
        immediately upon posting it here. You agree to periodically review the
        current version of the Privacy Policy, as posted here. If you do not
        agree with the terms of this Privacy Policy, please do not use the
        Services offered by Kadam.
      </p>
      <p className="text-[0.9rem] py-1 text-headingGrey">
        1.4. We will not use or share your information with anyone except as
        described in this Privacy Policy or as required to sufficiently carry
        out the offered Services. We use your information to operate and improve
        the Services. By availing the Services and interacting with Kadam over
        the Website and/or Application, you agree to the collection and use of
        information in accordance with this Privacy Policy.
      </p>
      <p className="text-[0.9rem] py-1 text-headingGrey">
        1.5. This Privacy Policy is to be read with, and is an integral part of,
        our Terms of Use available at Web link of the Terms of Use (“Terms of
        Use”). Unless otherwise defined in this Privacy Policy, terms used in
        this Privacy Policy have the same meanings as in our Terms of Use.
      </p>
    </div>
  );
};

const ConsentSection = () => {
  return (
    <div>
      <h2 className="font-semibold text-[1.2rem] mt-4">2. Consent</h2>
      <p className="text-[0.9rem] py-1 text-headingGrey">
        2.1 You agree and consent to the collection of information specified in
        Clause 3 below as per Clause 4 of this Privacy Policy.
      </p>
      <p className="text-[0.9rem] py-1 text-headingGrey">
        2.2. Further, you agree and consent to receive all communications
        related to the Services at the mobile number provided by you to Kadam,
        even if such mobile number is registered under DND/NCPR list under the
        Telecom Commercial Communications Customer Preference Regulations, 2018
        (“TRAI Regulations”).
      </p>
      <p className="text-[0.9rem] py-1 text-headingGrey">
        2.3. Notwithstanding your registration with the National Do Not Call
        Registry (In Fully or Partly blocked category under National Customer
        Preference Register set up under the Telecom Regulatory Authority of
        India), you hereby express your interest and accord informed consent to
        receive communications (including commercial communications) in relation
        to the Services.
      </p>
      <p className="text-[0.9rem] py-1 text-headingGrey">
        2.4. You further confirm that no communication to you from Kadam or on
        behalf of Kadam shall be construed as Unsolicited Commercial
        Communication under Regulation 2(bw) of the TRAI Regulations and you
        have specifically opted to receive communications in this regard on the
        mobile number provided by you.
      </p>
      <p className="text-[0.9rem] py-1 text-headingGrey">
        2.5. For the purpose of providing the Services to you, you further
        authorize Kadam to share/disclose any information provided by you, to
        any third party service provider or any affiliates, group companies,
        their authorised agents or third party service providers, and such
        disclosure shall be governed by the terms of this Privacy Policy where
        it is Personal Information.
      </p>
    </div>
  );
};

const CollectionInformationSection = () => {
  return (
    <div>
      <h2 className="font-semibold text-[1.2rem] mt-4">
        3. Collection of Information
      </h2>
      <p className="text-[0.9rem] py-1 text-headingGrey">
        3.1. While you avail our Services and interact with us, we collect
        certain Personal Information that can be used to contact or identify
        you. Personal Information includes, but is not limited to, your phone
        number and other information that by itself or in conjunction with other
        information, can be used to specifically identify you. However, Personal
        Information does not include aggregated information that, by itself,
        does not enable identification.
      </p>
      <p className="text-[0.9rem] py-1 text-headingGrey">
        3.2. Information that we collect when you avail the Services offered by
        us includes:
      </p>
      <ul>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          i. Information provided by you: We may require you to provide us with
          certain information such as your name, age, email address, mobile
          number, postal address, email address, identity proof, address proof,
          driving license etc. Further, if you communicate with us via the
          Application, the Website, or through email, we may collect any
          information, including your Personal Information, provided in such
          communication.
        </p>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          ii. Log Data: We collect information that your browser or device sends
          us whenever you avail our Services <b>("Log Data")</b>. This Log Data
          includes information such as your Internet Protocol <b>("IP")</b>
          address,geolocation and geospatial data, browser details, domain
          servers, device ID & model number, details of your operating system,
          and other related information. We also collect data on the time and
          date of your engagement with our Services, the time spent on your
          engagement with our Services, default language and user preferences,
          and other similar usage related statistics. We may assign each user of
          the Services unique identifiers to help keep track of future
          engagement by such user.
        </p>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          iii. Cookies: When you avail our Services, we may be able to access
          information via cookies. Cookies are files with small amounts of data
          which are transferred to your browser or device that allow our systems
          to recognise your browser or device and capture certain information.
          For example, we may use cookies to compile data about our operations,
          display relevant advertisements to you or to understand your
          preferences based on your previous activity to optimise your user
          experience. The cookies we send to your browser or device may also
          include anonymous unique identifiers, which we may use to collect data
          about your device, to create and store a unique identity of you for
          the purposes of fraud prevention, security, or authentication. Most
          browsers and devices are by default set up to accept cookies. You may
          reset your settings to refuse all cookies or to indicate when a cookie
          is being sent. However, it is likely that some of the features of our
          Services may not function properly if cookies are disabled on your
          browser or device. Please note that clicking on any links to
          third-party services may result in cookies being transmitted to your
          browser or device. In such events, you understand that except for
          cookies transmitted to you by intermediaries authorised by us for that
          purpose, this Privacy Policy does not cover the transmission and use
          of cookies by any third-parties. We bear no responsibility for the
          data collected by such third parties and encourage you to review and
          understand the relevant privacy policies of such third-parties before
          utilising their services.
        </p>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          iv. Information received from others: We may combine information about
          you that we already have with information we obtain from other
          sources, to provide you a better user experience, to show you
          advertisements relevant to you, or to optimise the Services offered by
          us. Examples of information we may receive from other sources includes
          without any limitation, contact information, identification
          information, date of birth, educational background, user support or
          enrolment information, page view, search term and search result
          information, and credit or identity information.
        </p>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          v. Vehicle Use Data: Vehicles will contain hardware that gathers and
          transmits information about vehicle use. This is done as a security
          measure against accident or theft and also to provide you with
          valuable services and information, such as other drivers’ data.
        </p>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          vi. Location Tracking: To prevent theft, and to allow us to locate you
          in case of emergency, accident, lock-out, etc., we track the location
          of your vehicle. Your location information will be confined to Kadam
          App, and we will never impermissibly make your location or movements’
          public. As part of our service, the location of your vehicle may be
          released to insurance companies, the police, or similar parties in the
          course of an investigation and/or accident claim, and to provide
          assistance in emergencies. Information regarding the location of each
          vehicle on Application is also transmitted to Kadam and can be tracked
          by your Kadam App-enabled smartphone.
        </p>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          vii. Driver and Vehicle Information: As a user of Kadam, you authorize
          us to access your driver’s record, vehicle history report from all
          applicable entities and authorities. This authorization continues for
          as long as you are a Kadam user. Moreover, you authorize all DMVs,
          RMVs, auto mechanics and all other entities and interested parties to
          release information to us regarding your driving record and vehicle
          history.
        </p>
      </ul>
      <p className="text-[0.9rem] py-1 text-headingGrey">
        3.3. Apart from the information listed above, we do not collect any
        sensitive information such as political opinions, health-related
        information or any information related to race, caste or religion
        (collectively,<b> “Sensitive Personal Information”</b>), unless provided
        by you voluntarily or required by provisions of any applicable law.
      </p>
    </div>
  );
};

const UseOfInformationSection = () => {
  return (
    <div>
      <h2 className="font-semibold text-[1.2rem] mt-4">
        4. Use of Information
      </h2>
      <p className="text-[0.9rem] py-1 text-headingGrey">
        4.1. We use your PII and other information collected from you for the
        following purposes:
        <ul>
          <p className="text-[0.9rem] py-1 text-headingGrey">
            i. To provide you with the functionalities of our Services in a
            proper manner;
          </p>
          <p className="text-[0.9rem] py-1 text-headingGrey">
            ii. To track your usage of our Services customise them accordingly
            to best suit your requirements;
          </p>
          <p className="text-[0.9rem] py-1 text-headingGrey">
            iii. To send you communications, as per your notification settings,
            on any updates or developments pertaining to our Services or your
            engagement with us over the Application and/or the Website;
          </p>
          <p className="text-[0.9rem] py-1 text-headingGrey">
            iv. To provide you with support services, whether through email,
            phone, push notifications or messages through the Application and/or
            the Website;
          </p>
          <p className="text-[0.9rem] py-1 text-headingGrey">
            v. To provide you with technical support, including updating
            Services;
          </p>
          <p className="text-[0.9rem] py-1 text-headingGrey">
            vi. To improve the quality, features and functionality of our
            Services, including by seeking performance reviews or feedbacks
            pursuant to the recruitment opportunities provided through the
            Services;
          </p>
          <p className="text-[0.9rem] py-1 text-headingGrey">
            vii. For the purposes of auditing, research and analysis to ensure
            the stability of our Services;
          </p>
          <p className="text-[0.9rem] py-1 text-headingGrey">
            viii. To improve the security of the Services provided by us;
          </p>
          <p className="text-[0.9rem] py-1 text-headingGrey">
            ix. To carry out marketing analysis to understand the features of
            the Services that may be optimised;
          </p>
          <p className="text-[0.9rem] py-1 text-headingGrey">
            x. To back up our systems to ensure preparedness for disaster
            recovery;
          </p>
          <p className="text-[0.9rem] py-1 text-headingGrey">
            xi. To protect our rights and property and the rights and property
            of our other users.
          </p>
          <p className="text-[0.9rem] py-1 text-headingGrey">
            xii. To offer customized ad experience by us or google ads;
          </p>
          <p className="text-[0.9rem] py-1 text-headingGrey">
            xiii. To combine your Personal Information or other information with
            information obtained from other sources, and publish summaries
            online or offline.
          </p>
          <p className="text-[0.9rem] py-1 text-headingGrey">
            xiv. To provide information to third-parties performing services in
            support of our Services.
          </p>
          <p className="text-[0.9rem] py-1 text-headingGrey">
            xv. To communicate promotions and other offers, and for the purposes
            of displaying advertisements relevant to you on the Website and/or
            Application.
          </p>
          <p className="text-[0.9rem] py-1 text-headingGrey">
            xvi. In pursuance of the terms and conditions of any arrangement or
            transaction with any third party, that we are currently engaged in
            or might potentially engage with in the future including without
            limitation, for the transfer of your Personal Information and other
            information in lieu of consideration.
          </p>
          <p className="text-[0.9rem] py-1 text-headingGrey">
            xvii. To verify identity, prevent and detect any fraud or other
            illegal activity carried on or through our Services, and to prevent
            violations of our Terms of Use.
          </p>
          <p className="text-[0.9rem] py-1 text-headingGrey">
            xviii. To respond to legal requirements, exercise our legal rights
            and defend against any legal claims, to protect our interests or
            those of any third parties; and
          </p>
          <p className="text-[0.9rem] py-1 text-headingGrey">
            xix. As may otherwise be necessary to enforce the terms of this
            Privacy Policy and the Terms of Use.
          </p>
        </ul>
      </p>
    </div>
  );
};

const StorageOfInformationSection = () => {
  return (
    <div>
      <h2 className="font-semibold text-[1.2rem] mt-4">
        {" "}
        5. Storage of Information
      </h2>
      <p className="text-[0.9rem] py-1 text-headingGrey">
        5.1. Your Personal Information or other information collected from you
        may be stored and processed on computer systems located outside India,
        and through your use of the Services you unequivocally consent to the
        processing and storage of your information outside of your own
        jurisdiction. Subject to Clause 7.1 below, you understand and agree that
        we may continue to store your information after you cease to use the
        Services or we disable your use of the Services.
      </p>
      <p className="text-[0.9rem] py-1 text-headingGrey">
        5.2. We store some of your information on your browser or device for
        subsequent retrieval and utilisation. Such information stored on your
        personal device is subject to the security offered by your device,
        browser, and other components. You understand and agree that we cannot
        be held responsible for any compromise of access to your personal
        browser or device, for loss of information, theft or for any exploits or
        logic attacks on your browser or device through malware or other
        software or hardware that is outside the control of Kadam and the scope
        of Services offered by us.
      </p>
      <p className="text-[0.9rem] py-1 text-headingGrey">
        5.3. Although the security of your Personal Information and other
        information is important to us, do remember that no method of electronic
        storage or method of transmission is 100% secure. While we strive to use
        commercially acceptable and standard means to protect your information,
        including internal reviews of our data collection, storage and
        processing practices and security measures, we cannot guarantee absolute
        security in this regard. You provide your Personal Information and other
        information to us at your own risk.
      </p>
    </div>
  );
};

const DisclosureAndSharingSection = () => {
  return (
    <div>
      <h2 className="font-semibold text-[1.2rem] mt-4">
        6. Disclosure and Sharing of Information
      </h2>
      <p className="text-[0.9rem] py-1 text-headingGrey">
        6.1. We may employ third parties to facilitate the functioning of the
        Services, to provide the Services’ functionalities on our behalf, to
        help us analyse how the Services are being used, to serve advertisements
        to our users, or to perform other related activities pertaining to our
        operations. We may share your information with third-parties in the
        following circumstances:
      </p>
      <ul>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          i. We have obtained your consent, including when you choose to provide
          us your information for any purpose whatsoever.
        </p>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          ii. For the purposes of carrying out the Services offered by Kadam,
          including but not limited to matching Users with employment
          opportunities offered by third parties.
        </p>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          iii.If sharing or disclosure of your PII or other information is
          necessary to operate the Services.
        </p>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          iv. For the purposes of displaying advertisements relevant to you.
        </p>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          v. If we have been purchased by a third-party.
        </p>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          vi. If sharing or disclosure of your PII or other information is
          necessary to be shared with our associate entities, including any
          related companies, successors, assigns, licensees, affiliates or
          business partners. We require that these entities process your
          Personal Information or other information as per our instructions and
          in compliance with the conditions of this Privacy Policy and other
          appropriate confidentiality and security measures.
        </p>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          vii. If your Personal Information or other information is demanded by
          a court order or by governmental or statutory authorities.
        </p>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          viii. If sharing or disclosure of your PII or other information is
          needed to help prevent against fraud or the violation of any
          applicable law, statute, regulation, ordinance, or treaty.
        </p>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          ix. If sharing or disclosure of your Personal Information or other
          information is needed to protect our employees, independent
          contractors, officers, members or other users; and
        </p>
        <p className="text-[0.9rem] py-1 text-headingGrey">
          x. If otherwise permitted to do so by this Privacy Policy or by
          applicable law.
        </p>
      </ul>
      <p className="text-[0.9rem] py-1 text-headingGrey">
        6.2. Unless otherwise required by law or necessary to carry out the
        Services as offered, when any Personal Information or other information
        pertaining to the Service users is shared with third-parties such as
        recruiters, company’s offering employment opportunities, advertisers,
        licensees and content distributors, we ensure that such information is
        non-private, aggregated or anonymised so that it will not identify you
        personally. You understand that we may further distribute your
        information to a wider audience through third-party sites and services,
        if such distribution is consistent with this clause and the contents of
        this Privacy Policy.
      </p>
      <p className="text-[0.9rem] py-1 text-headingGrey">
        6.3. We may share your Personal Information or other information with
        our parents, subsidiaries, divisions, and affiliates or as part of any
        sale, merger or acquisition.
      </p>
      <p className="text-[0.9rem] py-1 text-headingGrey">
        6.4 We will never sell your Personal Information or other information to
        unaffiliated third-parties without your approval, unless specifically
        laid out under this Privacy Policy.
      </p>
    </div>
  );
};

const InformationRetentionSection = () => {
  return (
    <div>
      <h2 className="font-semibold text-[1.2rem] mt-4">
        7. Information Retention
      </h2>
      <p className="text-[0.9rem] py-1 text-headingGrey">
        7.1. Upon you ceasing the use of our Services, we will archive your
        Personal Information. We will retain archived information indefinitely
        in our secure database for the purposes of our records. Non-personally
        identifiable information is retained indefinitely for analytics.
        However, any Sensitive Personal Information collected by us, if any,
        shall be destroyed upon such cease of use.
      </p>
      <p className="text-[0.9rem] py-1 text-headingGrey">
        7.2. We collect and retain certain information that is required for our
        detection, investigation and prevention of fraud, cheating and other
        violations of our Terms of Use and applicable laws <b>("Breach(es)")</b>
        . This data is used only for the purposes of detection, investigation,
        prevention and, where applicable, acting on such Breaches and stored
        only for the minimum amount of time needed for the purpose of dealing
        with such Breaches. If the data indicates that such a Breach has
        occurred, we will further store the data for the establishment, exercise
        or defence of legal claims, until a legal case related to it has been
        resolved, or such legal claim no longer exists due to the expiry of
        relevant limitation periods. Please note that the specific data stored
        for dealing with Breaches may not be disclosed to you if such disclosure
        will compromise our legal rights as well as the mechanism through which
        we detect, investigate and prevent such Breaches.
      </p>
    </div>
  );
};

const GoverningLawSection = () => {
  return (
    <div>
      <h2 className="font-semibold text-[1.2rem] mt-4">8. Governing Law</h2>
      <p className="text-[0.9rem] py-1 text-headingGrey">
        8.1. This Privacy Policy shall be governed by and constructed in
        accordance with the laws of India, without reference to conflict of laws
        principles. The courts in Gurugram, Haryana, India shall have the
        exclusive jurisdiction to determine any disputes arising in relation to,
        or under, this Privacy Policy.
      </p>
    </div>
  );
};

const ContactSection = () => {
  return (
    <div>
      <h2 className="font-semibold text-[1.2rem] mt-4">9. Contact Us</h2>
      <p className="text-[0.9rem] py-1 text-headingGrey">
        9.1. If you have any questions about this Privacy Policy or have any
        grievances, please contact our grievance officer, as provided below:
      </p>
      <p className="text-[0.9rem] py-1 text-headingGrey">Name: _____________</p>
      <p className="text-[0.9rem] py-1 text-headingGrey">
        Email: _____________
      </p>
      <h2 className="font-semibold text-[1.2rem] mt-4">
      10. Account and Data Deletion
      </h2>
      
      <p className="text-[0.9rem] py-1 text-headingGrey">
        10.1. To submit a request to delete your account or data, please get in
        touch at. Email: ________________, Ph. No. __________.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
