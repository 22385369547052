import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedFilter: {},
};

const jobVarSlice = createSlice({
  name: "jobVariables",
  initialState,
  reducers: {
    setJobFilters: (state, action) => {
      state.selectedFilter = action.payload;
    },
    removeSelectedCategory: (state, action) => {
      if (state.selectedFilter.category_id) {
        state.selectedFilter.category_id = undefined;
      }
    },

    removeSelectedCity: (state, action) => {
      if (state.selectedFilter.city_id?.length > 0) {
        state.selectedFilter.city_id = state.selectedFilter.city_id.filter(
          (item) => item !== action.payload
        );
      }
    },
    removeSelectedCompany: (state, action) => {
      if (state.selectedFilter.company_id?.length > 0) {
        state.selectedFilter.company_id =
          state.selectedFilter.company_id.filter(
            (item) => item !== action.payload
          );
      }
    },
    resetJobFilter: (state) => {
      state.selectedFilter = initialState.selectedFilter;
    },
  },
});

export const {
  setJobFilters,
  removeSelectedCategory,
  removeSelectedCity,
  removeSelectedCompany,
  resetJobFilter,
} = jobVarSlice.actions;

export default jobVarSlice.reducer;
