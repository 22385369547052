import { axiosInstance } from "../axios";

export const getRoleAccess = async (data) => {
  const roleID = localStorage.getItem("role_id");
  try {
    if (roleID) {
      const response = await axiosInstance.get(`/role-permission/${roleID}`);
      if (response?.status === 200) {
        return response.data?.data?.[0];
      }
    }
  } catch (error) {}
};
