import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedFilter: {},
};

const referredCandidateFilterSlice = createSlice({
  name: "referredCandidateSlice",
  initialState,
  reducers: {
    setReferredCandidatesFilters: (state, action) => {
      state.selectedFilter = action.payload;
    },
    removeReferredSelectedCategory: (state, action) => {
      if (state.selectedFilter.category_id) {
        state.selectedFilter.category_id = undefined;
      }
    },
    removeRefferedSelectedMobilizer: (state, action) => {
      if (state.selectedFilter.mobilizer_id) {
        state.selectedFilter.mobilizer_id = undefined;
      }
    },
    removeReferredCandidateSelectedCity: (state, action) => {
      if (state.selectedFilter.city_id?.length > 0) {
        state.selectedFilter.city_id = state.selectedFilter.city_id.filter(
          (item) => item !== action.payload
        );
      }
    },
    removeReferredCandidateSelectedStage: (state, action) => {
      if (state.selectedFilter.stage_id) {
        state.selectedFilter.stage_id = undefined;
      }
    },
    removeReferredSelectedCompany: (state, action) => {
      if (state.selectedFilter.company_id?.length > 0) {
        state.selectedFilter.company_id =
          state.selectedFilter.company_id.filter(
            (item) => item !== action.payload
          );
      }
    },
    resetFiltersReferredCandidates: (state) => {
      state.selectedFilter = initialState.selectedFilter;
    },
  },
});

export const {
  setReferredCandidatesFilters,
  removeReferredCandidateSelectedCity,
  removeReferredCandidateSelectedStage,
  resetFiltersReferredCandidates,
  removeReferredSelectedCompany,
  removeReferredSelectedCategory,
  removeRefferedSelectedMobilizer,
} = referredCandidateFilterSlice.actions;

export default referredCandidateFilterSlice.reducer;
